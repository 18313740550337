import React, { useState, useRef, useEffect, Children } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import '/src/components/Base/SlickSlider/slick.scss'
import '/src/components/Base/SlickSlider/slick-theme.scss'
import '../styles.scss'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const Button = loadable(() => import('/src/components/Basic/Buttons/Button'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const LargeCard = loadable(() => import('/src/components/Cards/LargeCard'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))

// Wizard is a single Formik instance whose children are each page of the
// multi-step form. The form is submitted on each forward transition (can only
// progress with valid input), whereas a backwards step is allowed with
// incomplete data. A snapshot of form state is used as initialValues after each
// transition. Each page has an optional submit handler, and the top-level
// submit is called when the final page is submitted.
const Wizard = ({
    initialValues,
    fieldValuesToUpdate,
    submitText,
    submitSuccessText,
    onSubmit,
    isSubmitted,
    successMessage,
    children
}) => {
    const formikRef = useRef()

    const [stepNumber, setStepNumber] = useState(0)
    const steps = Children.toArray(children)
    const [snapshot, setSnapshot] = useState(initialValues)

    const step = steps[stepNumber]
    const totalSteps = steps.length
    //const isFirstStep = stepNumber === 0
    const isLastStep = stepNumber === totalSteps - 1

    const next = (values) => {
        setSnapshot(values)
        setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
    }

    const previous = (values) => {
        setSnapshot(values)
        setStepNumber(Math.max(stepNumber - 1, 0))
    }

    const handleSubmit = async (values, bag) => {
        if (step.props.onSubmit) {
            await step.props.onSubmit(values, bag)
        }
        if (isLastStep) {
            return onSubmit(values, bag)
        } else {
            bag.setTouched({})
            next(values)
        }
    }

    useEffect(() => {
        if (formikRef.current && fieldValuesToUpdate) {
            fieldValuesToUpdate.forEach((node) => {
                formikRef.current.setFieldValue(
                    node.field,
                    node.value,
                    true // Revalidate
                )
            })
        }
    }, [formikRef, fieldValuesToUpdate])

    return (
        <>
            <div className="slick-dots">
                <ol>
                    {steps.map((node, index) => {
                        const number = index + 1
                        return (
                            <li key={index} className={`${stepNumber === index && 'slick-active'}`}>
                                <div className="slick-dots__item">
                                    <span className="slick-dots__item-number">{number}</span>
                                </div>
                            </li>
                        )
                    })}
                </ol>
            </div>

            <Formik
                initialValues={snapshot}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={step.props.validationSchema}
                innerRef={formikRef}
            >
                {({ values, isSubmitting, isValid, dirty }) => {
                    return (
                        <LargeCard>
                            <Form>
                                {/* Do this for journey form only
                            {stepNumber === 1 || stepNumber === 2 || stepNumber === 4 ? ( // If the slide has radio buttons, link the radios directly to the next step when clicked
                                <button className="c-wizard__invisible-submit" type="submit">
                                    {step}
                                </button>
                            ) : (
                                step
                            )} */}

                                {!isSubmitted
                                    ? step
                                    : // If a success message prop has been sent through, this should be down on the same page, and the page doesn't redirect when the form has been submitted
                                      successMessage && (
                                          <Alert
                                              variant="success"
                                              size="lg"
                                              content={successMessage}
                                              className="c-wizard__success-message"
                                          />
                                      )}

                                <hr className="c-wizard__divider" />

                                <CtaContainer align="centered" className="c-wizard__cta-container">
                                    {
                                        // Show the back button if it is past the first step, and if the form hasn't yet been submitted
                                        stepNumber > 0 && !isSubmitted && (
                                            <Button
                                                size="lg"
                                                variant="secondary"
                                                icon="chevron-back"
                                                onClick={() => previous(values)}
                                            >
                                                Back
                                            </Button>
                                        )
                                    }

                                    {!isLastStep ? (
                                        <Button type="submit" size="lg" icon="chevron" disabled={!isValid}>
                                            Next
                                        </Button>
                                    ) : (
                                        <SubmitButton
                                            initialText={submitText}
                                            sentText={submitSuccessText}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                            disabled={!isValid}
                                        />
                                    )}
                                </CtaContainer>
                            </Form>
                        </LargeCard>
                    )
                }}
            </Formik>
        </>
    )
}

Wizard.propTypes = {
    /**
     * Initial form field values
     */
    initialValues: PropTypes.object,
    /**
     * An array of objects - form fields which need to be updated with values
     */
    fieldValuesToUpdate: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ),
    /**
     * Submit button text
     */
    submitText: PropTypes.string,
    /**
     * Submit button success text
     */
    submitSuccessText: PropTypes.string,
    /**
     * Function to carry out upon submission of the form
     */
    onSubmit: PropTypes.func,
    /**
     * Form success message to go within alert component
     */
    successMessage: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

Wizard.defaultProps = {
    submitText: 'Submit',
    submitSuccessText: 'Enquiry sent!',
    successMessage: '<p>Thank you for contacting us. Someone will get back to you shortly.</p>',
    children: <p>Wizard steps go here</p>
}

export default Wizard
